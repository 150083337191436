/* responsive means these classes can be media queried
i.e. sm:pin-l-50 */
@responsive {
  /* positions */
  .pin-t-50  {
    top: 50%;
  }

  .pin-l-50  {
    left: 50%;
  }

  /* transforms */
  .transf-y-n50 {
    transform: translateY(-50%);
  }

  .transf-y-50 {
    transform: translateY(50%);
  }

  .transf-y-25 {
    transform: translateY(25%);
  }

  .transf-y-33 {
    transform: translateY(33%);
  }

  .transf-center {
    transform: translate(-50%, -50%);
  }

  .center-xy  {
    @apply relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .center-x  {
    @apply relative;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  /* display */
  .hidd {
    display: none;
  }
}

.popup-content {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  width: initial !important;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.75) !important;
}

.infotooltip {
  position: relative;
  display: inline-block;
  margin-left: 15rem;
}

.infotooltip .infotooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #8aadff;
  color: #000000;
  text-align: justify;
  padding: 5px 5px 5px 5px;
  border-radius: 6px;
  border-color: #4663ae;
  border-width: 1px;

  position: absolute;
  left: 25px;
  z-index: 1;
}

.infotooltip:hover .infotooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 600px) {
  .infotooltip .infotooltiptext { 
    width: 200px;
  }
  .infotooltip {
    margin-left: 0rem;
  }
}
