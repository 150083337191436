body,
html {
  height: 100%;
}
body,
html,
button,
input,
optgroup,
select,
textarea {
  @apply gotham;
}

@responsive {
  .modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(1, 17, 70);
    /* Fallback color */
    background-color: rgba(1, 17, 70, 0.8);
    /* Black w/ opacity */
  }

  .link {
    @apply cursor-pointer underline;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
    display: none;
    position: absolute;
  }

  .dropdown--active .dropdown__content {
    display: block;
  }

  .background-side {
    background-image: url("/img/home/side.jpg");
    height: auto;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .bg-boutique {
    @apply h-auto w-full bg-repeat-y;
    background-image: url("/img/boutique/boutique-bg.png");
    background-size: 100%;
  }

  .bg-side-boutique {
    @apply h-auto w-full bg-repeat-y;
    background-image: url("/img/boutique/side-boutique.png");
    background-size: 100%;
    padding-bottom: 1.5rem;
  }

  .background-none {
    background-image: none;
  }

  .jackpot-text-desktop {
    max-width: 43rem;
  }

  .jackpot-text-mobile {
    max-width: 400px;
  }

  .jackpot-text-mobile {
    max-width: 491px;
  }

  .circle-left-text-desktop {
    max-width: 491px;
  }

  .circle-middle-text-desktop {
    max-width: 491px;
  }

  .circle-right-text-desktop {
    max-width: 445px;
  }

  .circle-text-left-mobile {
    max-width: 278px;
  }

  .circle-text-middle-mobile {
    max-width: 278px;
  }

  .circle-text-right-mobile {
    max-width: 293px;
  }

  .bg-boutique-menu {
    background-image: linear-gradient(#61acdd, #3143a7);
  }

  .bg-boutique-menu-active {
    background-image: linear-gradient(#ffffff, #e0ecf7);
  }

  .easymillions-menu {
    background-image: linear-gradient(#ee8220, #d71320);
  }

  .bg-easymillions-menu-active {
    @apply pt-3 h-full shadow;
    background-image: linear-gradient(#ffffff69, #e0ecf769);
  }

  .header-account {
    top: -32px;
  }

  .blue-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1em;
    width: 350px;
    background-color: #3143a7;
    color: white;
    height: 45px;
    border-radius: 9999px;
    background-image: url("/img/close-png");

    background-repeat: no-repeat;
  }

  .payoffPlaceholder {
  }

  .payoffPlaceholder::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3c7acd;
    opacity: 1; /* Firefox */
  }

  .payoffPlaceholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3c7acd;
  }

  .payoffPlaceholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3c7acd;
  }

  .centered-circle {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .centered-star {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .centered-star-grid {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .centered-left-arrow {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
  }

  .centered-right-arrow {
    position: absolute;
    top: 50%;
    right: -21%;
    transform: translate(-50%, -50%);
  }

  .centered-right-arrow-mobile {
    position: absolute;
    top: 50%;
    right: -6%;
    transform: translate(-50%, -50%);
  }

  .valign {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    /* vendor prefixes omitted due to brevity */
  }

  .margin-bottom-0 {
    margin-bottom: 0rem !important;
  }

  .slider-image-desktop {
    width: 550px;
    height: 300px;
  }

  .logged-picture {
    height: 227px;
    width: 100%;
    background: url(/img/home/easyboutique-home.png) center center no-repeat;
  }

  .logged-picture-mobile {
    width: 100%;
    height: 227px;
    background: url(/img/home/easyboutique-home.png) center no-repeat;
    background-size: contain;
  }

  .logged-picture-2 {
    height: 227px;
    width: 100%;
    background: url(/img/home/easymillions-home.png) center center no-repeat;
  }

  .logged-picture-2-mobile {
    width: 100%;
    height: 227px;
    background: url(/img/home/easymillions-home.png) center no-repeat;
    background-size: contain;
  }

  .logged-picture-3 {
    height: 270px;
    width: 100%;
    background: url(/img/home/concursos.png) no-repeat;
    background-size: contain;
  }

  .logged-picture-3-mobile {
    width: 100%;
    height: 227px;
    background: url(/img/home/mobile/easymillions-red.png) center no-repeat;
    background-size: contain;
  }

  .desktop-header {
    top: 0%;
    left: 46%;
    z-index: 9999;
    width: 80px;
  }

  @media only screen and (max-width: 904px) {
    .desktop-header {
      left: 36%;
      width: 80px;
    }
  }

  @media only screen and (max-width: 767px) {
    .desktop-header {
      left: 85%;
      width: 50px;
    }
  }

  .desktop-home-stamp {
    width: 150px;
  }

  @media only screen and (max-width: 1000px) {
    .desktop-home-stamp {
      width: 100px;
    }
  }
}
