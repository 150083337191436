@font-face {
  font-family: 'Gotham Black';
  src: url('/font/hinted-GothamBlack.eot');
  src: local('Gotham Black'), local('GothamBlack'),
    url('/font/hinted-GothamBlack.eot?#iefix') format('embedded-opentype'),
    url('/font/hinted-GothamBlack.woff2') format('woff2'),
    url('/font/hinted-GothamBlack.woff') format('woff'),
    url('/font/hinted-GothamBlack.ttf') format('truetype'),
    url('/font/hinted-GothamBlack.svg#GothamBlack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('/font/hinted-Gotham-Book.eot');
  src: local('Gotham Book'), local('Gotham-Book'),
      url('/font/hinted-Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-Gotham-Book.woff2') format('woff2'),
      url('/font/hinted-Gotham-Book.woff') format('woff'),
      url('/font/hinted-Gotham-Book.ttf') format('truetype'),
      url('/font/hinted-Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('/font/hinted-Gotham-Medium.eot');
  src: local('Gotham Medium'), local('Gotham-Medium'),
      url('/font/hinted-Gotham-Medium.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-Gotham-Medium.woff2') format('woff2'),
      url('/font/hinted-Gotham-Medium.woff') format('woff'),
      url('/font/hinted-Gotham-Medium.ttf') format('truetype'),
      url('/font/hinted-Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-Gotham-Medium.eot');
  src: local('Gotham Medium'), local('Gotham-Medium'),
      url('/font/hinted-Gotham-Medium.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-Gotham-Medium.woff2') format('woff2'),
      url('/font/hinted-Gotham-Medium.woff') format('woff'),
      url('/font/hinted-Gotham-Medium.ttf') format('truetype'),
      url('/font/hinted-Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-GothamBlack.eot');
  src: local('Gotham Black'), local('GothamBlack'),
    url('/font/hinted-GothamBlack.eot?#iefix') format('embedded-opentype'),
    url('/font/hinted-GothamBlack.woff2') format('woff2'),
    url('/font/hinted-GothamBlack.woff') format('woff'),
    url('/font/hinted-GothamBlack.ttf') format('truetype'),
    url('/font/hinted-GothamBlack.svg#GothamBlack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-GothamBold.eot');
  src: local('Gotham Bold'), local('GothamBold'),
      url('/font/hinted-GothamBold.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-GothamBold.woff2') format('woff2'),
      url('/font/hinted-GothamBold.woff') format('woff'),
      url('/font/hinted-GothamBold.ttf') format('truetype'),
      url('/font/hinted-GothamBold.svg#GothamBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-GothamLight-Italic.eot');
  src: local('Gotham Light Italic'), local('GothamLight-Italic'),
      url('/font/hinted-GothamLight-Italic.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-GothamLight-Italic.woff2') format('woff2'),
      url('/font/hinted-GothamLight-Italic.woff') format('woff'),
      url('/font/hinted-GothamLight-Italic.ttf') format('truetype'),
      url('/font/hinted-GothamLight-Italic.svg#GothamLight-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-GothamBold-Italic.eot');
  src: local('Gotham Bold Italic'), local('GothamBold-Italic'),
      url('/font/hinted-GothamBold-Italic.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-GothamBold-Italic.woff2') format('woff2'),
      url('/font/hinted-GothamBold-Italic.woff') format('woff'),
      url('/font/hinted-GothamBold-Italic.ttf') format('truetype'),
      url('/font/hinted-GothamBold-Italic.svg#GothamBold-Italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/font/hinted-GothamLight.eot');
  src: local('Gotham Light'), local('GothamLight'),
      url('/font/hinted-GothamLight.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-GothamLight.woff2') format('woff2'),
      url('/font/hinted-GothamLight.woff') format('woff'),
      url('/font/hinted-GothamLight.ttf') format('truetype'),
      url('/font/hinted-GothamLight.svg#GothamLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('/font/hinted-GothamLight.eot');
  src: local('Gotham Light'), local('GothamLight'),
      url('/font/hinted-GothamLight.eot?#iefix') format('embedded-opentype'),
      url('/font/hinted-GothamLight.woff2') format('woff2'),
      url('/font/hinted-GothamLight.woff') format('woff'),
      url('/font/hinted-GothamLight.ttf') format('truetype'),
      url('/font/hinted-GothamLight.svg#GothamLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

.gotham {
  font-family: 'Gotham';
  font-weight: 500;
}

.gotham-black {
  font-family: 'Gotham Black';
}

.gotham-book {
  font-family: 'Gotham Book';
}

.gotham-medium {
  font-family: 'Gotham Medium';
}

.gotham-light {
  font-family: 'Gotham Light';
}